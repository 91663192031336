/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Box, Typography } from '@material-ui/core';
import { CloudUpload } from '@material-ui/icons';
import { toast } from 'react-toastify';
import Typewriter from 'typewriter-effect';
import ReactPlayer from 'react-player';

const MAX_FILE_SIZE = 100000000; // 100mb

const UploadButton = ({ onAttach }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState(null);

  const handleFileChange = event => {
    if (event.target.files.length === 0) {
      return;
    }
    if (event.target.files[0].size > MAX_FILE_SIZE) {
      toast.error('A imagem não pode ultrapassar 100mb');
      return;
    }

    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      setPreview(URL.createObjectURL(file));
      onAttach({ file, preview: URL.createObjectURL(file) });
    }
  };

  const handleClear = () => {
    setSelectedFile(null);
    setPreview(null);
    onAttach(null);
    document.getElementById('upload-button').value = '';
  };

  const renderPreview = () => {
    if (!selectedFile) return null;
    const fileType = selectedFile.type;

    const style = {
      marginTop: 10,
      width: 200,
      height: 'auto',
      borderRadius: 8,
    };

    if (fileType.includes('image')) {
      return <img src={preview} alt="Preview" style={style} />;
    }
    if (fileType.includes('video')) {
      return (
        <video src={preview} style={style}>
          <track kind="captions" />
        </video>
      );
    }

    if (fileType.includes('audio')) {
      return (
        <ReactPlayer
          url={preview}
          controls
          config={{
            file: { attributes: { controlsList: 'nodownload nodrag' } },
          }}
          height="60px"
          width="275px"
        />
      );
    }
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <input
        accept="image/*, video/*, audio/*"
        style={{ display: 'none' }}
        id="upload-button"
        type="file"
        onChange={handleFileChange}
      />
      <label htmlFor="upload-button">
        <Button
          variant="contained"
          color="primary"
          component="span"
          endIcon={<CloudUpload />}
        >
          Adicione u
          <Typewriter
            options={{
              strings: ['ma imagem', 'm vídeo', 'm audio'],
              autoStart: true,
              loop: true,
            }}
          />
        </Button>
      </label>
      {selectedFile && (
        <Box mt={2} display="flex" flexDirection="column" alignItems="center">
          <Typography variant="body1">
            Arquivo:{' '}
            {selectedFile.name.length > 20
              ? `${selectedFile.name.substr(
                  0,
                  10
                )}...${selectedFile.name.substr(selectedFile.name.length - 4)}`
              : selectedFile.name}
          </Typography>

          {preview && renderPreview()}

          <Button
            variant="outlined"
            color="secondary"
            onClick={handleClear}
            style={{ marginTop: 10 }}
          >
            Remover Arquivo
          </Button>
        </Box>
      )}
    </Box>
  );
};

UploadButton.propTypes = {
  onAttach: PropTypes.func.isRequired,
};

export default UploadButton;
