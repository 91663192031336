import React, { useState, useEffect } from 'react';
import {
  Modal,
  Backdrop,
  Fade,
  Button,
  Grid,
  Typography,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useConfirm } from 'material-ui-confirm';
import PropTypes from 'prop-types';

import Questoes from './Questoes';
import Preview from './Preview';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'auto',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    width: '1050px',
    height: '827px',
  },
  questoes: {
    maxHeight: '672px',
    overflowY: 'auto',
  },
  btOpen: {
    color: '#50A6FF',
    borderRadius: '200px',
  },
  header: {
    background: '#4D5884',
    height: '60px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(0, 6),
  },
  title: {
    color: '#F7F8FA',
  },
  footer: {
    height: '95px',
    background: 'rgba(234, 236, 245, 0.4)',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(0, 3),
  },
  button: {
    borderRadius: '200px',
    minWidth: '183px',
    margin: theme.spacing(0, 3),
  },
  buttonDialog: {
    borderRadius: '200px',
    minWidth: '120px',
    margin: theme.spacing(0, 1),
  },
  formTitulo: {
    padding: theme.spacing(4, 4, 2, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

export default function ModalQuestionario({
  addQuestionario,
  questionarioEditing,
  setQuestionarioEditing,
}) {
  const classes = useStyles();
  const confirm = useConfirm();
  const [open, setOpen] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [pesoTotal, setPesoTotal] = useState(0);
  const [questions, setQuestions] = useState([]);
  const [hasNewQuestion, setHasNewQuestion] = useState(false);
  const [titulo, setTitulo] = useState(
    (questionarioEditing && questionarioEditing.titulo) || ''
  );
  const [tempoLimite, setTempoLimite] = useState(
    (questionarioEditing && questionarioEditing.tempo_limite) || ''
  );

  const handleAddQuestion = () => {
    const id = questions.length + 1;
    setQuestions([
      ...questions,
      {
        id,
        enunciado: 'Nova questão',
        peso: 1,
        tipo: 1,
        tipoDescricao: 'Seleção única',
        opcoes: [
          {
            id: 1,
            descricao: '',
            correta: true,
          },
          {
            id: 2,
            descricao: '',
            correta: false,
          },
        ],
      },
    ]);
    setOpenForm(true);
    setHasNewQuestion(true);
  };

  useEffect(() => {
    if (Boolean(questionarioEditing)) {
      setQuestions(questionarioEditing.questoes || []);
      setOpen(true);
    }
  }, [questionarioEditing]); //eslint-disable-line

  useEffect(() => {
    if (questions.length > 0) {
      let peso = 0;
      questions.map(question => (peso += Number(question.peso))); //eslint-disable-line
      setPesoTotal(peso);
    }
  }, [questions]);

  const closeEditing = () => {
    setQuestions([]);
    setOpen(false);
    setHasNewQuestion(false);
    setQuestionarioEditing(null);
  };

  const handleCancel = () => {
    if (
      (!questionarioEditing && Boolean(questions)) ||
      (Boolean(questionarioEditing) &&
        questionarioEditing.questoes !== questions)
    ) {
      confirm({
        description:
          'Ao confirmar, toda a configuração realizada será perdida.',
        title: 'Deseja continuar?',
        confirmationText: 'Continuar',
        cancellationText: 'Cancelar',
        confirmationButtonProps: {
          variant: 'contained',
          className: classes.buttonDialog,
        },
        cancellationButtonProps: {
          variant: 'contained',
          color: 'secondary',
          className: classes.buttonDialog,
        },
      })
        .then(() => closeEditing())
        .catch(() => {});
    } else {
      closeEditing();
    }
  };

  const handleChangeTempoLimite = event => {
    setTempoLimite(event.target.value);
  };

  const handleConfirm = () => {
    addQuestionario({
      peso_total: pesoTotal,
      questoes: questions,
      titulo,
      tempo_limite: tempoLimite,
    });
    closeEditing();
  };

  return (
    <>
      <Button
        type="button"
        onClick={() => {
          setOpen(true);
        }}
        variant="outlined"
        color="primary"
        className={classes.btOpen}
        fullWidth
      >
        + Questionário
      </Button>
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          onClose={() => setOpen(false)}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <div className={classes.paper}>
              <Grid container>
                <Grid item xs={12} className={classes.header}>
                  <Typography variant="h5" className={classes.title}>
                    Questionário
                  </Typography>
                  <Typography variant="caption" color="primary">
                    Peso total de {pesoTotal} ponto(s)
                  </Typography>
                </Grid>
                <Grid item xs={7} className={classes.questoes}>
                  <div className={classes.formTitulo}>
                    <TextField
                      label="Título"
                      defaultValue={titulo}
                      name="titulo"
                      variant="filled"
                      fullWidth
                      margin="normal"
                      size="small"
                      onChange={event => setTitulo(event.target.value)}
                    />
                    <TextField
                      label="Tempo limite"
                      type="time"
                      defaultValue={tempoLimite}
                      name="tempo_limite"
                      variant="filled"
                      fullWidth
                      margin="normal"
                      size="small"
                      onChange={handleChangeTempoLimite}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                  <Questoes
                    openForm={openForm}
                    setOpenForm={setOpenForm}
                    questions={questions}
                    setQuestions={setQuestions}
                    addQuestion={handleAddQuestion}
                    hasNewQuestion={hasNewQuestion}
                    setHasNewQuestion={setHasNewQuestion}
                  />
                </Grid>
                <Grid item xs={5}>
                  <Preview questionario={questions} tempoLimite={tempoLimite} />
                </Grid>
                <Grid item xs={12} className={classes.footer}>
                  <div>
                    {questions.length !== 0 && (
                      <Button
                        variant="outlined"
                        color="primary"
                        className={classes.button}
                        onClick={handleAddQuestion}
                        disabled={questions[questions.length - 1].peso <= 0}
                      >
                        Adicionar Nova Questão
                      </Button>
                    )}
                  </div>
                  <div>
                    <Button
                      variant="contained"
                      color="secondary"
                      className={classes.button}
                      onClick={handleCancel}
                    >
                      Cancelar
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      onClick={handleConfirm}
                    >
                      Salvar
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Fade>
        </Modal>
      </div>
    </>
  );
}

ModalQuestionario.propTypes = {
  addQuestionario: PropTypes.func.isRequired,
  questionarioEditing: PropTypes.shape(),
  setQuestionarioEditing: PropTypes.func.isRequired,
};

ModalQuestionario.defaultProps = {
  questionarioEditing: null,
};
