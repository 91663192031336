import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Typography } from '@material-ui/core';
import Image from 'material-ui-image';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import { toast } from 'react-toastify';

import Loading from '~/components/Loading';

import imgPreviewConferencia from '~/assets/preview_conferencia.png';
import imgConteudoDesativado from '~/assets/ilustra_conteudo_encerrado.svg';
import api from '~/services/api';

export default function Jitsi({ hasUrlConferencia }) {
  const transmissao = useSelector(state => state.transmissao.aula);
  const unidade = useSelector(state => state.unidade.unidade_id);
  const profile = useSelector(state => state.professor.profile);

  const [showLoading, setLoading] = useState(false);
  const [urlConferencia, setUrlConferencia] = useState(transmissao.url);

  const isConteudoPublicado = () =>
    transmissao.status === 3 || transmissao.status_id === 3;

  const isConteudoPublicadoSemUrl = () =>
    isConteudoPublicado() && !urlConferencia;

  const isConteudoDesativado = () => {
    const statusDesativado =
      transmissao.status === 4 || transmissao.status_id === 4;

    return statusDesativado || isConteudoPublicadoSemUrl();
  };

  const isConteudoTransmitindo = () =>
    transmissao.status === 2 || transmissao.status_id === 2;

  const generateToken = async () => {
    setLoading(true);
    try {
      const transmissaoId = transmissao.videoaula_id || transmissao.id;
      const sala = `${unidade}-${transmissaoId}`;
      const response = await api.post('/create/jitsi-token', {
        context: {
          user: {
            name: `${profile.nome}`,
            email: `${profile.login}`,
          },
        },
        aud: 'SalaDeAulaJitsi',
        iss: 'SalaDeAulaJitsi',
        sub: 'meet.f10.com.br',
        room: `${sala}`,
        moderator: false,
      });

      const { token } = response.data;

      setUrlConferencia(`https://meet.f10.com.br/${sala}?jwt=${token}`);
      hasUrlConferencia(true);
    } catch (error) {
      toast.error('Erro ao gerar autenticação para a sala de aula');
    }
    setLoading(false);
  };

  useEffect(() => {
    try {
      if (isConteudoTransmitindo()) {
        generateToken();
      }
    } catch (error) {
      setLoading(false);
    }
  }, [transmissao]); //eslint-disable-line

  const showVideoConferencia = () => {
    if (isConteudoTransmitindo()) {
      return (
        <iframe
          allow="camera; microphone; fullscreen; display-capture"
          src={urlConferencia}
          title="video-conferencia"
          width="100%"
          height="600px"
        />
      );
    }

    if (isConteudoPublicado() && !isConteudoPublicadoSemUrl()) {
      return (
        <ReactPlayer
          url={transmissao.url || transmissao.streaming_url}
          controls
        />
      );
    }

    if (isConteudoDesativado()) {
      return (
        <>
          <Image
            src={imgConteudoDesativado}
            style={{
              display: 'flex',
              paddingTop: 'unset',
              backgroundColor: 'none',
              justifyContent: 'center',
            }}
            imageStyle={{
              width: 'unset',
              height: 'unset',
              position: 'unset',
            }}
          />
          <Typography
            color="primary"
            variant="h5"
            component="div"
            align="center"
          >
            Fim da Transmissão
          </Typography>
          <Typography
            color="secondary"
            variant="body1"
            component="div"
            align="center"
          >
            A transmissão da videoaula foi encerrada ;)
          </Typography>
        </>
      );
    }

    return (
      <Image
        src={imgPreviewConferencia}
        style={{
          display: 'flex',
          paddingTop: 'unset',
          justifyContent: 'center',
        }}
        imageStyle={{
          width: '640px',
          height: 'unset',
          position: 'absolute',
          cursos: 'pointer',
        }}
      />
    );
  };

  return <>{(showLoading && <Loading />) || showVideoConferencia()}</>;
}

Jitsi.propTypes = {
  hasUrlConferencia: PropTypes.func.isRequired,
};
