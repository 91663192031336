import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Grid, Typography, Paper, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ReactPlayer from 'react-player';

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(4, 5, 10, 5),
    background: '#BDC0D5',
    height: '673px',
  },
  title: {
    fontWeight: 'bold',
  },
  preview: {
    width: '367px',
    height: '498px',
    overflow: 'auto',
    padding: '16px 13px',
    position: 'relative',
  },
  previewTitle: {
    marginBottom: '-2px',
  },
  enunciado: {
    color: '#4D5884',
    fontWeight: 'bold',
    fontSize: '11px',
    marginTop: '14px',
    marginBottom: '7px',
  },
  checkbox: {
    background: '#E3E4ED',
  },
  opcao: {
    color: '#4D5884',
    fontSize: '9px',
    display: 'flex',
    marginBottom: '7px',
  },
  spanCheck: {
    width: '13px',
    height: '13px',
    border: '1px solid #868CAB',
    background: '#E3E4ED',
    borderRadius: '4px',
    marginRight: '5px',
  },
  input: {
    background: '#E3E4ED',
    border: '1px solid #868CAB',
    borderRadius: '4px',
    width: '100%',
    height: '23px',
  },
  textarea: {
    height: '62px',
  },
  placeholderInput: {
    color: '#868CAB',
    letterSpacing: '0.4px',
    lineHeight: '20px',
    fontSize: '9px',
    marginLeft: '7px',
  },
  timerCard: {
    position: 'absolute',
    bottom: 0,
    left: '50%',
    transform: 'translateX(-50%)',
    background: '#4D5884',
    borderRadius: '8px',
    padding: theme.spacing(1, 2),
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(2),
    color: 'white',
    width: 'fit-content',
  },
  timer: {
    fontWeight: 'bold',
    fontSize: '14px',
  },
  timerText: {
    fontSize: '10px',
  },
}));

export default function Preview({ questionario, tempoLimite }) {
  const classes = useStyles();

  const renderPreview = media => {
    if (!media) return null;
    const fileType = media.file.type;

    const style = { width: '50px', height: 'auto', marginBottom: '7px' };

    if (fileType.includes('image')) {
      return <img src={media.preview} alt="Preview" style={style} />;
    }
    if (fileType.includes('video')) {
      return (
        <video src={media.preview} style={style}>
          <track kind="captions" />
        </video>
      );
    }

    if (fileType.includes('audio')) {
      return (
        <ReactPlayer
          url={media.preview}
          controls
          config={{ file: { attributes: { controlsList: 'nodownload' } } }}
          style={{ width: '100%', height: 'auto' }}
          height="50px"
          width="175px"
        />
      );
    }
  };

  const renderPreviewUrl = url => {
    return (
      <ReactPlayer
        url={url}
        controls
        height="100px"
        width="200px"
        style={{ marginBottom: '10px', width: '100%', height: 'auto' }}
      />
    );
  };

  const getPreviewQuestao = questao => {
    const htmlRespostas = [];

    switch (questao.tipo) {
      case 2:
        htmlRespostas.push(
          <div className={classes.input}>
            <span className={classes.placeholderInput}>
              Digite a sua resposta
            </span>
          </div>
        );
        break;
      case 3:
        htmlRespostas.push(
          <div className={clsx(classes.input, classes.textarea)}>
            <span className={classes.placeholderInput}>
              Digite a sua resposta
            </span>
          </div>
        );
        break;

      default:
        questao.opcoes.map(opcao => {
          htmlRespostas.push(
            <Typography
              variant="caption"
              component="p"
              className={classes.opcao}
            >
              <div className={classes.spanCheck} /> {opcao.descricao}
            </Typography>
          );
        });
        break;
    }

    return (
      <>
        <Typography
          variant="caption"
          component="p"
          color="secondary"
          className={classes.enunciado}
        >
          {questao.id} - {questao.enunciado}
        </Typography>
        {questao.media && (
          <Box display="flex" justifyContent="center">
            {renderPreview(questao.media)}
          </Box>
        )}
        {questao.url && renderPreviewUrl(questao.url)}
        {htmlRespostas.map(resposta => resposta)}
      </>
    );
  };

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <Typography className={classes.title} variant="body1">
          Como está ficando seu questionário
        </Typography>
      </Grid>
      <Grid item xs={12} component={Paper} className={classes.preview}>
        <Typography
          variant="body1"
          color="primary"
          component="p"
          className={classes.previewTitle}
        >
          Questionário
        </Typography>
        {questionario.map(questao => getPreviewQuestao(questao))}

        {tempoLimite && (
          <Box className={classes.timerCard}>
            <Typography className={classes.timer}>{tempoLimite}</Typography>
            <Typography className={classes.timerText}>
              Esse é o tempo para finalizar a atividade
            </Typography>
          </Box>
        )}
      </Grid>
    </Grid>
  );
}

Preview.propTypes = {
  questionario: PropTypes.arrayOf(PropTypes.shape()),
  tempoLimite: PropTypes.string,
};

Preview.defaultProps = {
  questionario: [],
  tempoLimite: '',
};
