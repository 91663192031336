import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  MenuItem,
  Typography,
  Checkbox,
  IconButton,
  FormControlLabel,
  TextField,
  FormControl,
  InputLabel,
  Select,
  debounce,
  Box,
  Divider,
  Button,
  InputAdornment,
} from '@material-ui/core';
import {
  AddCircle,
  RemoveCircle,
  Delete,
  InsertLink,
  Close,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { useConfirm } from 'material-ui-confirm';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import UploadButton from './ImageUpload';

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.primary.main}`,
    marginTop: theme.spacing(4),
    borderRadius: '15px',
  },
  opcao: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  sectionTitle: {
    color: '#4D5884',
    fontWeight: 'bold',
  },
  gridTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  button: {
    borderRadius: '200px',
    minWidth: '120px',
    margin: theme.spacing(0, 1),
  },
  box: {
    border: `1px dashed ${theme.palette.primary.main}`,
    padding: theme.spacing(2),
    borderRadius: 8,
  },
}));

const urlSchema = Yup.string().url('URL inválida');

export default function QuestaoForm({
  question,
  updateQuestions,
  deleteQuestion,
}) {
  const classes = useStyles();
  const confirm = useConfirm();
  const [urlFormOpen, setUrlFormOpen] = useState(false);
  const [urlError, setUrlError] = useState('');
  const formPesoRef = useRef(null);
  const formEnunciadoRef = useRef(null);
  const [peso, setPeso] = useState(question.peso);
  useEffect(() => {
    formPesoRef.current.value = question.peso;
    formEnunciadoRef.current.value = question.enunciado;
  }, [question]);

  useEffect(() => {
    updateQuestions({ ...question, peso });
  }, [peso]); // eslint-disable-line react-hooks/exhaustive-deps

  const questionTypes = [
    { id: 1, description: 'Seleção única' },
    { id: 2, description: 'Texto curto' },
    { id: 3, description: 'Texto longo' },
    { id: 4, description: 'Verdadeiro ou falso' },
    { id: 5, description: 'Multipla escolha' },
  ];

  const handleChangeForm = event => {
    if (event.target.name === 'tipo') {
      const questionType = questionTypes.find(
        type => type.id === event.target.value
      );

      const newConfigQuestion = {
        ...question,
        [event.target.name]: event.target.value,
        tipoDescricao: questionType.description,
      };

      switch (questionType.id) {
        case 1:
        case 5:
          newConfigQuestion.opcoes = [
            {
              id: 1,
              descricao: '',
              correta: true,
            },
            {
              id: 2,
              descricao: '',
              correta: false,
            },
          ];
          break;
        case 4:
          newConfigQuestion.opcoes = [
            {
              id: 1,
              descricao: 'Verdadeiro',
              correta: true,
            },
            {
              id: 2,
              descricao: 'Falso',
              correta: false,
            },
          ];
          break;
        default:
          delete newConfigQuestion.opcoes;
          break;
      }

      updateQuestions({
        ...newConfigQuestion,
      });

      return;
    }

    if (event.target.name === 'peso') {
      if (
        !event.target.value ||
        event.target.value < 0 ||
        event.target.value.match(/\s\.\s\s/)
      ) {
        updateQuestions({ ...question, peso: 0.0 });
      } else {
        updateQuestions({
          ...question,
          peso: event.target.value,
        });
      }

      return;
    }

    updateQuestions({ ...question, [event.target.name]: event.target.value });
  };

  const handleChangeFormOpcao = opcao => {
    const newConfigQuestion = { ...question };
    newConfigQuestion.opcoes.map(opcaoMap => {
      if (newConfigQuestion.tipo === 5) {
        if (opcaoMap.id === opcao.id) {
          opcaoMap.correta = !opcaoMap.correta;
        }
      } else if (opcaoMap.id === opcao.id) {
        opcaoMap.correta = true;
      } else {
        opcaoMap.correta = false;
      }
    });
    updateQuestions({ ...newConfigQuestion });
  };

  const handleBlurOptionForm = (descricao, id) => {
    const opcoes = [...question.opcoes];
    opcoes.map(opcao => {
      if (opcao.id === id) {
        opcao.descricao = descricao;
      }
    });

    updateQuestions({
      ...question,
      opcoes,
    });
  };

  const handleClickAddOption = () => {
    updateQuestions({
      ...question,
      opcoes: [
        ...question.opcoes,
        {
          id: question.opcoes.length + 1,
          descricao: '',
          correta: false,
        },
      ],
    });
  };

  const handleClickRemoveOption = id => {
    const opcoesFilter = question.opcoes.filter(option => option.id !== id);
    const opcaoRemovida = question.opcoes.find(option => option.id === id);

    opcoesFilter.map((option, key) => {
      option.id = key + 1;
    });

    if (question.tipo !== 5 && opcaoRemovida.correta) {
      if (id === 1) {
        opcoesFilter[0].correta = true;
      } else {
        opcoesFilter[id - 2].correta = true;
      }
    }

    updateQuestions({
      ...question,
      opcoes: [...opcoesFilter],
    });
  };

  const handleClickRemove = () => {
    confirm({
      description: '',
      title: 'Tem certeza que deseja excluir esta questão?',
      confirmationText: 'Confirmar',
      cancellationText: 'Cancelar',
      confirmationButtonProps: {
        variant: 'contained',
        className: classes.button,
      },
      cancellationButtonProps: {
        variant: 'contained',
        color: 'secondary',
        className: classes.button,
      },
    })
      .then(() => deleteQuestion(question))
      .catch(() => {});
  };

  const handleAttachImage = media => {
    updateQuestions({
      ...question,
      media,
    });
  };

  const handleOpenUrlForm = () => {
    setUrlFormOpen(true);
    setUrlError('');
  };

  const handleCloseUrlForm = () => {
    setUrlFormOpen(false);
    setUrlError('');
  };

  const handleAttachUrl = urlAttached => {
    if (urlAttached === '') {
      updateQuestions({
        ...question,
        url: '',
      });
    } else {
      urlSchema
        .validate(urlAttached)
        .then(() => {
          setUrlError('');
          updateQuestions({
            ...question,
            url: urlAttached,
          });
        })
        .catch(error => {
          console.log(error);
          toast.error('A URL deve seguir o formato: "https://www.example.com"');
          setUrlError(
            'URL inválida: a URL deve seguir o formato "https://www.example.com"'
          );
        });
    }
  };

  const handleChangePeso = e => {
    if (Number.isNaN(Number(e.target.value))) {
      e.target.value = question.peso;
      return;
    }

    const value = e.target.value.replace('.', '').replace(',', '');
    e.target.value = (value / 100).toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    if (e.target.value > 10) {
      e.target.value = 10;
    }

    setPeso(e.target.value);
  };

  return (
    <Grid container className={classes.container} spacing={1}>
      <Grid item xs={12} className={classes.gridTitle}>
        <Typography
          variant="body2"
          color="secondary"
          className={classes.sectionTitle}
        >
          {question.id} - {question.enunciado}
        </Typography>
        <IconButton onClick={handleClickRemove} size="small">
          <Delete color="secondary" />
        </IconButton>
      </Grid>
      <Grid item xs={12} lg={6}>
        <FormControl variant="filled" margin="normal" fullWidth size="small">
          <InputLabel id="select-tipo">Tipo de questão</InputLabel>
          <Select
            labelId="select-tipo"
            name="tipo"
            value={question.tipo}
            onChange={debounce(handleChangeForm, 1000)}
          >
            {questionTypes.map(type => (
              <MenuItem key={type.id} value={type.id}>
                {type.description}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} lg={6}>
        <TextField
          label="Peso da questão"
          defaultValue={question.peso}
          name="peso"
          variant="filled"
          fullWidth
          margin="normal"
          size="small"
          type="string"
          onChange={handleChangePeso}
          required
          error={question.peso <= 0}
          helperText={question.peso <= 0 && 'O peso não pode ser igual a 0.'}
          inputRef={formPesoRef}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Enunciado da questão"
          defaultValue={question.enunciado}
          name="enunciado"
          variant="filled"
          fullWidth
          margin="normal"
          size="small"
          onChange={debounce(handleChangeForm, 1000)}
          inputRef={formEnunciadoRef}
        />
      </Grid>
      <Grid item xs={12}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          gap={2}
          className={classes.box}
        >
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            {!urlFormOpen && (
              <Grid item xs={12}>
                <UploadButton onAttach={handleAttachImage} />
              </Grid>
            )}
            {urlFormOpen && (
              <Grid item xs={12}>
                <TextField
                  error={!!urlError}
                  helperText={urlError}
                  label="URL do vídeo"
                  onChange={debounce(e => {
                    handleAttachUrl(e.target.value);
                  }, 1000)}
                  fullWidth
                  variant="filled"
                  margin="normal"
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleCloseUrlForm} size="small">
                          <Close />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            )}
            {!urlFormOpen && (
              <>
                <Grid item xs={5}>
                  <Divider variant="middle" />
                </Grid>
                <Grid item xs={2}>
                  <Typography align="center">ou</Typography>
                </Grid>
                <Grid item xs={5}>
                  <Divider variant="middle" />
                </Grid>
                <Grid item xs={12}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      endIcon={<InsertLink />}
                      onClick={handleOpenUrlForm}
                    >
                      Adicione o link do vídeo
                    </Button>
                  </Box>
                </Grid>
              </>
            )}
          </Grid>
        </Box>
      </Grid>
      {question.opcoes && (
        <Grid item xs={12}>
          <Typography
            variant="body2"
            color="secondary"
            component="p"
            className={classes.sectionTitle}
          >
            Respostas
          </Typography>
        </Grid>
      )}
      {question.opcoes &&
        question.opcoes.map(opcao => (
          <React.Fragment key={`${new Date().getTime()}${Math.random(9999)}`}>
            <Grid item xs={7}>
              <TextField
                label={`Opção ${opcao.id}`}
                name={`opcao_${opcao.id}`}
                variant="filled"
                fullWidth
                margin="normal"
                size="small"
                onBlur={event => {
                  handleBlurOptionForm(event.target.value, opcao.id);
                }}
                inputProps={{ defaultValue: opcao.descricao }}
              />
            </Grid>
            <Grid item xs={3} className={classes.opcao}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    defaultChecked={opcao.correta}
                    onChange={() => handleChangeFormOpcao(opcao)}
                    name="correta"
                  />
                }
                label="Correta"
              />
            </Grid>
            <Grid item xs={1} className={classes.opcao}>
              {question.opcoes.length > 2 && (
                <IconButton
                  size="small"
                  color="secondary"
                  onClick={() => handleClickRemoveOption(opcao.id)}
                >
                  <RemoveCircle color="secondary" />
                </IconButton>
              )}
            </Grid>
            <Grid item xs={1} className={classes.opcao}>
              {opcao.id === question.opcoes.length && question.tipo !== 4 && (
                <IconButton
                  size="small"
                  color="primary"
                  onClick={handleClickAddOption}
                >
                  <AddCircle color="primary" />
                </IconButton>
              )}
            </Grid>
          </React.Fragment>
        ))}
    </Grid>
  );
}

QuestaoForm.propTypes = {
  question: PropTypes.shape().isRequired,
  updateQuestions: PropTypes.func.isRequired,
  deleteQuestion: PropTypes.func.isRequired,
};
