import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import Image from 'material-ui-image';
import { toast } from 'react-toastify';

import history from '~/services/history';
import api from '~/services/api';
import Breadcrumbs from '~/components/CustomUi/CustomBreadcrumbs';
import CadastradoRealizado from '~/assets/contrato_cadastrado.svg';

import StepDadosPessoais from './DadosPessoais';
import StepDadosTitular from './DadosTitular';
import StepCurso from './DadosCurso';
import StepCobranca from './DadosCobranca';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  description: {
    display: 'flex',
    justifyContent: 'center',
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '17px 0 20px',
  },
  successMessage: {
    color: '#47D19D',
    textAlign: 'center',
  },
  numeroContrato: {
    width: '100%',
    borderRadius: '20px',
    backgroundColor: '#f1f1f1',
    display: 'flex',
    justifyContent: 'center',
    margin: '17px 0',
  },
  buttonText: {
    color: '#fff',
  },
}));

function getSteps() {
  return [
    'Dados pessoais',
    'Dados do titular',
    'Dados do curso',
    'Dados de cobrança',
  ];
}

function getStepsDescriptions(stepIndex) {
  switch (stepIndex) {
    case 0:
      return 'Nome, documentos e endereços';
    case 1:
      return 'Nome, documentos e endereços';
    case 2:
      return 'Informações sobre o curso do contrato';
    case 3:
      return 'Informações para cobrança do curso';
    default:
      return 'Passo não encontrado';
  }
}

export default function Novo() {
  const classes = useStyles();
  const unidade_id = useSelector(state => state.unidade.unidade_id);

  const [activeStep, setActiveStep] = useState(0);
  const [dadosPessoais, setDadosPessoais] = useState({});
  const [dadosTitular, setDadosTitular] = useState({});
  const [dadosCurso, setDadosCurso] = useState({});
  const [dadosCobranca, setDadosCobranca] = useState({});
  const [openConfirmationExit, setOpenConfirmationExit] = useState(false);
  const [openConfirmarCadastro, setOpenConfirmarCadastro] = useState(false);
  const [openCadastroRealizado, setOpenCadastroRealizado] = useState(false);
  const [numeroContrato, setNumeroContrato] = useState(false);
  const steps = getSteps();

  const stepDescription = step => {
    return getStepsDescriptions(step);
  };

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  function confirmSubmitContrato() {
    setOpenConfirmarCadastro(true);
  }

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <StepDadosPessoais
            nextStep={handleNext}
            setDadosPessoais={setDadosPessoais}
            setDadosTitular={setDadosTitular}
            initialData={dadosPessoais}
            setOpenConfirmationExit={setOpenConfirmationExit}
          />
        );
      case 1:
        return (
          <StepDadosTitular
            nextStep={handleNext}
            setDadosTitular={setDadosTitular}
            setDadosPessoais={setDadosPessoais}
            initialDataAluno={dadosPessoais}
            initialDataTitular={dadosTitular}
            previousStep={handleBack}
            setOpenConfirmationExit={setOpenConfirmationExit}
          />
        );
      case 2:
        return (
          <StepCurso
            nextStep={handleNext}
            previousStep={handleBack}
            setDadosCurso={setDadosCurso}
            setOpenConfirmationExit={setOpenConfirmationExit}
            initialDataCurso={dadosCurso}
          />
        );
      case 3:
        return (
          <StepCobranca
            nextStep={handleNext}
            previousStep={handleBack}
            setDadosCobranca={setDadosCobranca}
            setOpenConfirmationExit={setOpenConfirmationExit}
            confirmSubmitContrato={confirmSubmitContrato}
          />
        );
      default:
        return 'Unknown stepIndex';
    }
  }

  function handleCancelConfirmationExit() {
    setOpenConfirmationExit(false);
  }

  function handleOkConfirmationExit() {
    history.push('/contratos');
  }

  function handleCancelCadastro() {
    setOpenConfirmarCadastro(false);
  }

  async function handleOkCadastro() {
    setOpenConfirmarCadastro(false);
    let titular_id = null;
    let aluno_id;

    if (!dadosPessoais.alunoTitular) {
      await api
        .post('/pessoa', dadosTitular)
        .then(response => {
          titular_id = response.data.pessoa_id;
        })
        .catch(() => {
          toast.error('Ocorreu um erro ao tentar salvar os dados do titular.');
        });
    }

    await api
      .post('/pessoa', dadosPessoais)
      .then(response => {
        aluno_id = response.data.pessoa_id;
      })
      .catch(() => {
        toast.error('Ocorreu um erro ao tentar salvar os dados do aluno.');
      });

    await api
      .post('/contrato', {
        unidade_id,
        materia: dadosCurso.materias,
        titular_id,
        aluno_id,
        curso_id: dadosCurso.curso_id,
        tipo: dadosCurso.tipo,
        data_matricula: dadosCurso.data_matricula,
        data_inicioaula: dadosCurso.data_inicioaula,
        observacoes: dadosCurso.observacoes,
        banco_id: dadosCobranca.banco_id,
        dia_vencimento: dadosCobranca.dia_vencimento,
        desconto: dadosCobranca.desconto,
        matricula_valor: dadosCobranca.matricula_valor,
        matricula_parcelas: dadosCobranca.matricula_parcelas,
        matricula_vencimento: dadosCobranca.matricula_vencimento,
        parcela_valor: dadosCobranca.parcela_valor,
        parcela_vencimento: dadosCobranca.parcela_vencimento,
        parcela_parcelas: dadosCobranca.parcela_parcelas,
        material_valor: dadosCobranca.material_valor,
        material_vencimento: dadosCobranca.material_vencimento,
        material_parcelas: dadosCobranca.material_parcelas,
      })
      .then(response => {
        setNumeroContrato(response.data.contrato_id);
        setOpenCadastroRealizado(true);
      })
      .catch(() => {
        toast.error('Ocorreu um erro ao tentar salvar os dados do aluno.');
      });
  }

  return (
    <>
      <Breadcrumbs
        categorias={['Comercial', 'Contratos']}
        funcionalidade="Novo Contrato"
      />
      <div className={classes.title}>
        <Typography variant="h5">Novo Contrato</Typography>
      </div>
      <Paper className={classes.root}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label, index) => {
            const stepProps = {
              completed: false,
            };
            const labelProps = {};
            labelProps.optional = (
              <Typography
                variant="caption"
                color="secondary"
                className={classes.description}
              >
                {stepDescription(index)}
              </Typography>
            );
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <div>
          <div>{getStepContent(activeStep)}</div>
        </div>
      </Paper>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        aria-labelledby="confirmation-dialog-title"
        open={openConfirmationExit}
      >
        <DialogContent dividers>
          <Typography>
            Tem certeza que deseja sair desse cadastro? Nessa ação você perderá
            todos os dados já preenchidos.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleOkConfirmationExit}
            color="primary"
            variant="outlined"
          >
            Sair
          </Button>
          <Button
            autoFocus
            onClick={handleCancelConfirmationExit}
            color="primary"
            variant="contained"
            className={classes.buttonText}
          >
            Permanecer
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        aria-labelledby="confirmation-dialog-title"
        open={openConfirmarCadastro}
      >
        <DialogContent dividers>
          <Typography>
            Ao confirmar o cadastro será realizado, deseja continuar?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleCancelCadastro}
            color="primary"
            variant="outlined"
          >
            Cancelar
          </Button>
          <Button
            onClick={handleOkCadastro}
            color="primary"
            variant="contained"
            className={classes.buttonText}
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        aria-labelledby="confirmation-dialog-title"
        open={openCadastroRealizado}
      >
        <DialogContent dividers>
          <Image
            src={CadastradoRealizado}
            style={{
              paddingTop: 'unset',
              background: 'none',
              display: 'flex',
              justifyContent: 'center',
            }}
            imageStyle={{
              width: 'unset',
              height: 'unset',
              position: 'unset',
            }}
          />
          <Typography variant="h5" className={classes.successMessage}>
            Cadastro concluído com sucesso, o número deste contrato é
          </Typography>
          <Typography
            variant="h5"
            className={classes.numeroContrato}
            color="primary"
          >
            {numeroContrato}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenCadastroRealizado(false);
              history.push('/contratos');
            }}
            color="primary"
            variant="outlined"
          >
            Voltar para a lista
          </Button>
          <Button
            autoFocus
            onClick={() => {
              setActiveStep(0);
              setDadosPessoais({});
              setDadosTitular({});
              setDadosCurso({});
              setDadosCobranca({});
              setOpenCadastroRealizado(false);
              history.push('/novo-contrato');
            }}
            color="secondary"
            variant="contained"
            className={classes.buttonText}
          >
            Novo Cadastro
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
