import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Form } from '@rocketseat/unform';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import ReactLoading from 'react-loading';
import { makeStyles } from '@material-ui/core/styles';
import {
  Modal,
  Backdrop,
  Fade,
  IconButton,
  Grid,
  Typography,
  InputAdornment,
  TextField,
  Button,
  FormControlLabel,
  FormControl,
  MenuItem,
} from '@material-ui/core';
import {
  AddCircleOutlineOutlined,
  DeleteOutlineOutlined,
} from '@material-ui/icons';

import api from '~/services/api';
import apiAtividadeStorage from '~/services/apiAtividadeStorage';
import Select from '~/components/CustomUi/FormSelect';
import CustomTextField from '~/components/CustomUi/TextField';
import Checkbox from '~/components/Utils/Checkbox';

import ModalUpload from './ModalUpload';
import ModalQuestionario from './ModalQuestionario';
import GridComplementos from './GridComplementos';

const schema = Yup.object().shape({
  conteudo_smart: Yup.bool(),
  curso: Yup.number()
    .required()
    .typeError('O curso é obrigatório')
    .when('conteudo_smart', {
      is: true,
      then: Yup.number().required('O curso é obrigatório'),
    }),
  materia: Yup.number()
    .positive()
    .required()
    .typeError('A matéria é obrigatória')
    .when('conteudo_smart', {
      is: true,
      then: Yup.number().required('A matéria é obrigatória'),
    })
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  aula: Yup.number()
    .positive()
    .nullable()
    .typeError('A aula é obrigatória')
    .when('conteudo_smart', {
      is: true,
      then: Yup.number().required('A aula é obrigatória'),
    })
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  tipo_atividade: Yup.number()
    .positive()
    .nullable(true)
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .typeError('O tipo da atividade é obrigatório'),
  titulo: Yup.string().required('O título é obrigatório'),
  tipo_resposta: Yup.number()
    .positive()
    .nullable(true)
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .typeError('O tipo da resposta é obrigatório'),
  descricao: Yup.string().required('A descrição é obrigatória'),
  objetivos: Yup.string(),
});

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    width: '849px',
    height: '820px',
    overflow: 'auto',
    borderRadius: '8px',
    padding: theme.spacing(2, 3),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '15px 0',
  },
  btAdd: {
    marginLeft: '10px',
    color: '#fff',
    borderRadius: '200px',
  },
  complementos: {
    maxHeight: '104px',
    overflow: 'auto',
  },
  complemento: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    background: 'rgba(80, 166, 255, 0.4)',
    alignItems: 'center',
    height: '39px',
    borderRadius: '8px',
    marginBottom: '5px',
  },
  link: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: '22px',
  },
  linkLabel: {
    color: '#868CAB',
    marginRight: '10px',
    fontWeight: 'bold',
  },
  linkValue: {
    fontWeight: 'bold',
    color: '#50A6FF',
  },
  btRemoveComplemento: {
    color: '#50A6FF',
    marginRight: '10px',
  },
  tituloSessao: {
    color: '#50A6FF',
  },
  subtituloSessao: {
    color: '#868CAB',
  },
  btCancelar: {
    borderRadius: '200px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  btAnexo: {
    color: '#fff',
    borderRadius: '200px',
  },
}));

export default function ModalFormAtividade({ open, handleClose }) {
  const classes = useStyles();
  const cursos = useSelector(state => state.cursos.data);
  const lookup = useSelector(state => state.lookup);
  const unidade = useSelector(state => state.unidade.unidade_id);

  const [materias, setMaterias] = useState([]);
  const [aulas, setAulas] = useState([]);

  const [cursoEscolhido, setCursoEscolhido] = useState(null);
  const [materiaEscolhida, setMateriaEscolhida] = useState(null);
  const [aulaEscolhida, setAulaEscolhida] = useState(null);
  const [tipoAtividadeEscolhida, setTipoAtividadeEscolhida] = useState(null);
  const [tipoRespostaEscolhida, setTipoRespostaEscolhida] = useState(null);
  const [conteudoChecked, setConteudoChecked] = useState(false);

  const [linksComplementos, setLinksComplementos] = useState([]);
  const [questionario, setQuestionario] = useState(null);
  const [questionarioEditing, setQuestionarioEditing] = useState(null);
  const [linkDigitado, setLinkDigitado] = useState(null);
  const [showLoading, setShowLoading] = useState(false);
  const [arquivosUpload, setArquivosUpload] = useState([]);

  const handleChangeCurso = async event => {
    const response = await api.get(`curso-materias/${event.target.value}`);

    setMaterias(response.data);
    setCursoEscolhido(event.target.value || null);
    setAulaEscolhida(null);
    setMateriaEscolhida(null);
    setConteudoChecked(false);
  };

  const handleChangeMateria = async event => {
    const response = await api.get(`materia/${event.target.value}/aulas`);

    setAulas(response.data);
    setMateriaEscolhida(event.target.value || null);
    setConteudoChecked(false);
    setAulaEscolhida(null);
  };

  const handleChangeAula = async event => {
    setConteudoChecked(false);
    setAulaEscolhida(event.target.value || null);
  };

  function adicionarLink(link) {
    return !!link && setLinksComplementos([...linksComplementos, link]);
  }

  function removerComplemento(link) {
    const index = linksComplementos.indexOf(link);
    const newArray = [...linksComplementos];
    newArray.splice(index, 1);
    setLinksComplementos(newArray);
  }

  const getExtension = extension => {
    switch (extension) {
      case 'application/pdf':
        return 'pdf';
      case 'image/png':
        return 'png';
      case 'image/jpeg':
        return 'jpeg';
      case 'image/jpg':
        return 'jpg';
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return 'docx';
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return 'xlsx';
      case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        return 'pptx';
      default:
        throw new Error('Invalid type.');
    }
  };

  function handleAddArquivos(arquivo) {
    const fileName = `${new Date().getTime()}${Math.random(9999)}`;
    const extension = getExtension(arquivo.type);
    arquivo.fileName = `${fileName}.${extension}`;
    setArquivosUpload([...arquivosUpload, arquivo]);
  }

  const handleDeleteComplemento = complementoAtividade => {
    if (complementoAtividade.fileName) {
      const index = arquivosUpload.indexOf(complementoAtividade);
      const newArray = [...arquivosUpload];
      newArray.splice(index, 1);
      setArquivosUpload(newArray);
    } else {
      setQuestionario(null);
      setQuestionarioEditing(null);
    }
  };

  const handleEditQuestionario = questionarioEdited => {
    setQuestionarioEditing(questionarioEdited);
  };

  const handleChangeCheckContuedo = event => {
    setConteudoChecked(event.target.checked);
  };

  const adicionarQuestionario = questionarioAdded => {
    setQuestionario(questionarioAdded);
  };

  async function handleSubmit({
    titulo,
    descricao,
    objetivos,
    conteudo_smart,
  }) {
    try {
      const arquivosUpados = [];
      const unidadeFormatada = `0000${unidade}`.slice(-5);
      setShowLoading(true);

      const questionatioHasMedia =
        questionario &&
        questionario.questoes.some(question => Boolean(question.media));

      const response = await api.post('/atividade', {
        curso: cursoEscolhido,
        materia: materiaEscolhida,
        aula: aulaEscolhida,
        tipo_atividade: tipoAtividadeEscolhida,
        titulo,
        tipo_resposta: tipoRespostaEscolhida,
        descricao,
        objetivos,
        anexos: linksComplementos,
        conteudo_smart,
        questionario: questionatioHasMedia ? null : questionario,
      });

      if (!response.data) {
        toast.error('Ocorreu um erro ao tentar salvar a atividade.');
        setShowLoading(false);
      }

      const { atividade_id } = response.data;

      if (!!arquivosUpload) {
        await Promise.all(
          arquivosUpload.map(arquivo => {
            const config = {
              headers: { 'Content-Type': `${arquivo.type}` },
            };
            const putURL = `${process.env.REACT_APP_ORACLE_URL_UPLOAD_ATIVIDADE}${unidadeFormatada}/atividades/${atividade_id}/${arquivo.fileName}`;
            apiAtividadeStorage
              .put(putURL, arquivo, config)
              .then(resp => {
                console.log('Feito?', resp.data);
              })
              .catch(err => {
                console.error(`${putURL}\nUPLOAD ERROR: ${err}`);
              });
            arquivosUpados.push(
              `${process.env.REACT_APP_ORACLE_URL_DOWNLOAD_ATIVIDADE}${unidadeFormatada}%2Fatividades%2F${atividade_id}%2F${arquivo.fileName}`
            );
          })
        );

        await api.put(`/anexos-atividade/${atividade_id}`, {
          anexos: arquivosUpados,
        });
      }

      if (questionatioHasMedia) {
        await Promise.all(
          questionario.questoes.map(async question => {
            if (question.media) {
              const config = {
                headers: { 'Content-Type': question.media.file.type },
              };
              const putURL = `${process.env.REACT_APP_ORACLE_URL_UPLOAD_ATIVIDADE}${unidadeFormatada}/atividades/${atividade_id}/${question.media.file.name}`;
              await apiAtividadeStorage
                .put(putURL, question.media.file, config)
                .then(resp => {
                  console.log('Feito?', resp.data);
                })
                .catch(err => {
                  console.error(`${putURL}\nUPLOAD ERROR: ${err}`);
                })
                .finally(() => {
                  question.media = `${process.env.REACT_APP_ORACLE_URL_DOWNLOAD_ATIVIDADE}${unidadeFormatada}/atividades/${atividade_id}/${question.media.file.name}`;
                });
            }
          })
        )
          .then(async () => {
            await api.put(`/questionario-atividade/${atividade_id}`, {
              questionario,
            });
          })
          .catch(err => {
            console.error(err);
          });
      }

      setShowLoading(false);
      toast.success('Atividade salva com sucesso.');
      handleClose();
    } catch (error) {
      setShowLoading(false);
    }
  }

  const getComplementos = () => {
    const complementos = [...arquivosUpload];
    if (Boolean(questionario)) {
      complementos.push({ ...questionario, name: questionario.titulo });
    }

    return complementos;
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={() => handleClose()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Form
              onSubmit={handleSubmit}
              schema={schema}
              className={classes.form}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h5" className={classes.tituloSessao}>
                    Dados do Curso
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <FormControl variant="filled" fullWidth>
                    <Select
                      label="Curso"
                      name="curso"
                      onChange={handleChangeCurso}
                      value={cursoEscolhido}
                    >
                      <MenuItem value="">Selecione</MenuItem>
                      {cursos &&
                        cursos
                          .filter(curso => curso.ativo)
                          .map(curso => (
                            <MenuItem
                              key={curso.curso_id}
                              value={curso.curso_id}
                            >
                              {curso.descricao}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl variant="filled" fullWidth>
                    <Select
                      label="Matéria"
                      name="materia"
                      onChange={handleChangeMateria}
                      value={materiaEscolhida}
                    >
                      <MenuItem value="">Selecione</MenuItem>
                      {materias &&
                        materias.map(materia => (
                          <MenuItem
                            key={materia.conteudo_id}
                            value={materia.conteudo_id}
                          >
                            {materia.descricao}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl variant="filled" fullWidth>
                    <Select
                      label="Aula"
                      name="aula"
                      onChange={handleChangeAula}
                      value={aulaEscolhida}
                    >
                      <MenuItem value="">Selecione</MenuItem>
                      {aulas &&
                        aulas.map(aula => (
                          <MenuItem
                            key={aula.conteudo_id}
                            value={aula.conteudo_id}
                          >
                            {aula.descricao}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    disabled={
                      !cursoEscolhido || !materiaEscolhida || !aulaEscolhida
                    }
                    control={
                      <Checkbox
                        name="conteudo_smart"
                        color="primary"
                        onChange={handleChangeCheckContuedo}
                        checked={conteudoChecked}
                      />
                    }
                    label="Atividade pertence a conteúdos de aula?"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h5" className={classes.tituloSessao}>
                    Dados da Atividade
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <FormControl variant="filled" fullWidth>
                    <Select
                      label="Tipo de lançamento"
                      name="tipo_atividade"
                      value={tipoAtividadeEscolhida}
                      onChange={event =>
                        setTipoAtividadeEscolhida(event.target.value)
                      }
                    >
                      <MenuItem value="">Selecione</MenuItem>
                      {lookup.tipoAtividadeLancamento &&
                        lookup.tipoAtividadeLancamento.map(tipo => (
                          <MenuItem key={tipo.intkey} value={tipo.intkey}>
                            {tipo.descricao}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <CustomTextField
                    name="titulo"
                    label="Título da atividade"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <Select
                    label="Tipo de resposta"
                    name="tipo_resposta"
                    value={tipoRespostaEscolhida}
                    onChange={event =>
                      setTipoRespostaEscolhida(event.target.value)
                    }
                  >
                    <MenuItem value="">Selecione</MenuItem>
                    {lookup.tipoRespostaLancamento &&
                      lookup.tipoRespostaLancamento.map(tipo => (
                        <MenuItem key={tipo.intkey} value={tipo.intkey}>
                          {tipo.descricao}
                        </MenuItem>
                      ))}
                  </Select>
                </Grid>
                <Grid item xs={12}>
                  <CustomTextField
                    name="descricao"
                    label="Descrição da atividade"
                    margin="normal"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomTextField
                    name="objetivos"
                    label="Objetivos da atividade"
                    margin="normal"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h5" className={classes.tituloSessao}>
                    Complementos para atividade
                  </Typography>
                  <Typography
                    variant="body1"
                    component="p"
                    className={classes.subtituloSessao}
                  >
                    Se precisar, adicione links para complementar a atividade
                  </Typography>
                  <TextField
                    name="link"
                    label="Link"
                    placeholder="Digite um link"
                    variant="filled"
                    color="primary"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            type="button"
                            onClick={() => adicionarLink(linkDigitado)}
                          >
                            <AddCircleOutlineOutlined
                              className={classes.icon}
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    margin="normal"
                    fullWidth
                    autoComplete="off"
                    onChange={e => {
                      setLinkDigitado(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12} className={classes.complementos}>
                  {!!linksComplementos &&
                    linksComplementos.map(link => (
                      <div className={classes.complemento} key={link}>
                        <div className={classes.link}>
                          <Typography
                            variant="body1"
                            className={classes.linkLabel}
                          >
                            Link
                          </Typography>
                          <Typography
                            variant="body1"
                            className={classes.linkValue}
                          >
                            {link}
                          </Typography>
                        </div>
                        <IconButton
                          className={classes.btRemoveComplemento}
                          size="small"
                          onClick={() => removerComplemento(link)}
                        >
                          <DeleteOutlineOutlined />
                        </IconButton>
                      </div>
                    ))}
                </Grid>
                {arquivosUpload.length < 2 && (
                  <Grid item xs={6}>
                    <ModalUpload handleAddArquivos={handleAddArquivos} />
                  </Grid>
                )}
                {!Boolean(questionario) && (
                  <Grid item xs={6}>
                    <ModalQuestionario
                      addQuestionario={adicionarQuestionario}
                      questionarioEditing={questionarioEditing}
                      setQuestionarioEditing={setQuestionarioEditing}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <GridComplementos
                    complementos={getComplementos()}
                    removerComplemento={handleDeleteComplemento}
                    editarComplemento={handleEditQuestionario}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} className={classes.buttons}>
                  {(showLoading && (
                    <ReactLoading type="cylon" color="#868CAB" />
                  )) || (
                    <>
                      <Button
                        type="button"
                        onClick={() => {
                          handleClose();
                        }}
                        variant="contained"
                        color="secondary"
                        className={classes.btCancelar}
                      >
                        Cancelar
                      </Button>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.btAdd}
                      >
                        Adicionar Atividade
                      </Button>
                    </>
                  )}
                </Grid>
              </Grid>
            </Form>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

ModalFormAtividade.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};
